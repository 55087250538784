<template>
  <div>
    <div class="h-handle-bg">
      <div class="h-handle-button">
        <p class="h-return">
          <el-button type="text" size="small" @click="$parent.checkSaveStatus">返回</el-button>
        </p>
        <div class="h-b">
          <el-button type="primary" size="small" @click="add" v-right-code="'Sysconfig:Create'">新增</el-button>
        </div>
        <div class="h-b">
          <el-button type="text" size="small" @click="save" v-right-code="'Sysconfig:Create'">保存</el-button>
        </div>
      </div>
    </div>

    <div class="form-list">
      <el-form :model="dataSource" ref="_sysConfigForm" :rules="sysConfigCheckRule">
        <el-form-item>
          <el-col :span="3" class="form-title"><span style="color: red">*</span> 配置键：</el-col>
          <el-col :span="17">
            <el-form-item prop="ConfigKey">
              <el-input v-model="dataSource.ConfigKey" :disabled="isDisabled" :minlength="2" placeholder="键"></el-input>
            </el-form-item>
          </el-col>
        </el-form-item>
        <el-form-item>
          <el-col :span="3" class="form-title">配置值：</el-col>
          <el-col :span="17">
            <el-form-item prop="ConfigValue">
              <el-input type="textarea" v-model="dataSource.ConfigValue" placeholder="值"></el-input>
            </el-form-item>
          </el-col>
        </el-form-item>

        <el-form-item>
          <el-col :span="3" class="form-title">配置项关联编码：</el-col>
          <el-col :span="17">
            <el-form-item prop="ConfigRelationCode">
              <el-input v-model="dataSource.ConfigRelationCode"></el-input>
            </el-form-item>
          </el-col>
        </el-form-item>

        <el-form-item>
          <el-col :span="3" class="form-title">配置描述：</el-col>
          <el-col :span="17">
            <el-form-item prop="ConfigMemo">
              <el-input type="textarea" v-model="dataSource.ConfigMemo"></el-input>
            </el-form-item>
          </el-col>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isDisabled: false,
      sysConfigCheckRule: {
        ConfigKey: [
          {
            required: true,
            message: '请输入配置键',
            trigger: 'blur',
          },
        ],
        ConfigValue: [
          {
            required: true,
            message: '请输入配置值',
            trigger: 'blur',
          },
        ],
        //ConfigMemo: [{ required: true, message: '请输入配置描述', trigger: 'blur' }]
      },
    }
  },
  props: {
    config: {
      isDetailDisplay: false,
    },
    dataSource: {},
  },
  mounted() {
    this.Event.$on('clearEditSysConfigForm', () => this.resetForm)
  },
  watch: {
    dataSource: {
      handler(curVal, oldVal) {
        this.isDisabled = this.Utils.isGreaterThanZero(
          this.dataSource.ConfigId
        )
      },
      deep: true,
    },
  },
  methods: {
    add() {
      this.Event.$emit('onAdd')
    },
    resetForm() {
      !this.isPassValidate && this.$refs['_sysConfigForm'].resetFields() //清空表单
    },
    save() {
      var _this = this
      _this.$refs['_sysConfigForm'].validate((valid) => {
        _this.isPassValidate = valid
        if (valid) {
          var routeName = !_this.Utils.isGreaterThanZero(
            _this.dataSource.ConfigId
          )
            ? 'create'
            : 'edit'
          _this.$ajax.send(
            'omsapi/sysconfig/' + routeName,
            'post',
            _this.dataSource,
            (data) => {
              if (
                !_this.Utils.isGreaterThanZero(
                  _this.dataSource.ConfigId
                )
              ) {
                _this.dataSource.ConfigId = data.Result
              }
              _this.$parent.syncDataSource()
              _this.Event.$emit('reloadPageList', data.Result)
              _this.Utils.messageBox('保存成功.', 'success')
            }
          )
        } else {
          return false
        }
      })
    },
  },
}
</script>
